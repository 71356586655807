/** @jsx jsx */
import { darken, lighten } from "@theme-ui/color"
import React, { useState, useEffect } from "react"
import { Select, Button, Box, jsx, Message } from "theme-ui"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { useStaticQuery } from "gatsby"
import SubscriptionForm from "./forms/SubscriptionForm"
import SanitizedHTMLBodyCopy from "../sanitizedHTMLBodyCopy"

const stripe = loadStripe(
  "pk_live_51IxDSWFjBziK5zZO2uSIW5uSfHKdhA9N0M1DdukHFJwDu0TxaUxPFIMRVR85OF01Mh8g52x47DxrcK5FZlYQxwXR00hnwN0hVs"
)

const SubscribeAppPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      strapiFriendTiers {
        tier1
        tier2
        tier3
      }
    }
  `)
  //expired account?
  const urlParams = new URLSearchParams(location.search)
  const expired = urlParams.get("expired")
  // console.log("expired", expired)

  const [buyState, setBuyState] = useState(false)
  const [subscriptionTier, setSubscriptionTier] = useState(
    "Friend_Tier1_Subscribed"
  )
  const [subscriptionTierReadable, setSubscriptionTierReadable] = useState(
    "Graduate (for Friends Aged 31 - 35)"
  )
  const [subscriptionTierText, setSubscriptionTierText] = useState(
    data.strapiFriendTiers.tier1
  )
  const [total, setTotal] = useState(6000)

  const handleTierChange = event => {
    setSubscriptionTier(event.target.value)
    if (event.target.value === "Friend_Tier1_Subscribed") {
      setTotal(6000)
      setSubscriptionTierReadable("Graduate")
      setSubscriptionTierText(data.strapiFriendTiers.tier1)
    } else if (event.target.value === "Friend_Tier2_Subscribed") {
      setTotal(12000)
      setSubscriptionTierReadable("Encore")
      setSubscriptionTierText(data.strapiFriendTiers.tier2)
    } else if (event.target.value === "Friend_Tier3_Subscribed") {
      setTotal(60000)
      setSubscriptionTierReadable("Encore+")
      setSubscriptionTierText(data.strapiFriendTiers.tier3)
    }
    // } else if (event.target.value === "Test_Subscribed") {
    //   setTotal(100)
    //   setSubscriptionTierReadable("Test Subscribed")
    //   setSubscriptionTierText("Test subscripton to test Strapi")
    // }
  }

  const handleSubmit = event => {
    event.preventDefault()
    setBuyState(true)
  }

  return (
    <Box sx={styles} className="subscribePage">
      <div>
        <h1>Subscribe as a Friend</h1>
        {expired && (
          <Message className="message">
            <h3>Account Expired</h3>
            Your account has expired. This might be because your Friend
            Subscription has run out or that you have recently turned 31. Please
            use the form below to (re)join Masterclass as a Friend and continue
            to support the charity and it's aims.
          </Message>
        )}

        <p>Become a Friend of Masterclass and help support our charity.</p>

        <div>
          <h3>Graduate (for Friends Aged 31 - 35) </h3>
          <p>What's Included:</p>
          <ul>
            <li>Support Young Creatives</li>
            <li>Masterclasses from Industry Legends</li>
            <li>Access to Afterclasses</li>
            <li>Join Script Social Events</li>
            <li>Access to a community of Theatremakers</li>
            <li>Offers & Discounts from our Theatre Partners</li>
            <li>Cancel Anytime</li>
          </ul>
          <p>
            <a href="https://buy.stripe.com/cN2eWo2RabVLa4weVd">
              Prefer to pay monthly? Pay £5 a month!
            </a>
            <br />
            If you pay monthly please allow 2 working days to receive your
            welcome email.
          </p>
        </div>

        <div>
          <h3>Encore (for Friends aged 36+)</h3>
          <p>What's Included:</p>
          <ul>
            <li>Support Young Creatives</li>
            <li>Observe Masterclasses</li>
            <li>Access to Afterclasses</li>
            <li>Invitation to curated events including Masterclass Matinees</li>
            <li>Offers and Discounts from our Theatre Partners</li>
            <li>Friends e-newsletters</li>
            <li>Cancel Anytime</li>
          </ul>
          <p>
            <a href="https://buy.stripe.com/cN23dGbnG9NDgsU8wA">
              Prefer to pay monthly? Pay £10 a month!
            </a>
            <br />
            If you pay monthly please allow 2 working days to receive your
            welcome email.
          </p>
        </div>

        <div>
          <h3>Encore+ (for Friends aged 36+)</h3>
          <p>What's Included:</p>
          <ul>
            <li>Support Young Creatives</li>
            <li>Masterclasses from Industry Legends</li>
            <li>Access to Afterclasses</li>
            <li>Join Script Social Events</li>
            <li>Access to a community of Theatremakers</li>
            <li>Offers & Discounts from our Theatre Partners</li>
            <li>Cancel Anytime</li>

            <li>Support Young Creatives</li>
            <li>Observe Masterclasses </li>
            <li>Access to Afterclasses</li>
            <li>
              Invitation to curated events including Masterclass Matinees{" "}
            </li>
            <li>Offers and Discounts from our Theatre Partners</li>
            <li>Friends e-newsletters</li>
            <li>
              Personal thank you on Masterclass website's virtual donor wall{" "}
            </li>
            <li>
              25% discount on a pair of tickets to any curated events throughout
              the year{" "}
            </li>
            <li>Cancel Anytime</li>
          </ul>
          <p>
            <a href="https://buy.stripe.com/4gw8y03Ve0d3dgI149">
              Prefer to pay monthly? Pay £50 a month!
            </a>
            <br />
            If you pay monthly please allow 2 working days to receive your
            welcome email.
          </p>
        </div>

        <h3>
          <strong>Total:</strong> £{(total / 100).toFixed(2)} -{" "}
          {subscriptionTierReadable}
        </h3>
        <SanitizedHTMLBodyCopy content={subscriptionTierText} />
      </div>
      <div className="formContainer">
        {buyState ? (
          <Elements stripe={stripe}>
            <SubscriptionForm
              total={total}
              subscriptionTier={subscriptionTier}
              subscriptionTierReadable={subscriptionTierReadable}
              onChange={() => setBuyState(false)}
            />
          </Elements>
        ) : (
          <div>
            <Select
              default="Friend_Tier3_Subscribed"
              onChange={handleTierChange}
            >
              <option value="Friend_Tier1_Subscribed">
                Graduate (for Friends Aged 31 - 35) £60.00
              </option>
              <option value="Friend_Tier2_Subscribed">
                Encore (for Friends aged 36+) £120.00
              </option>
              <option value="Friend_Tier3_Subscribed">
                Encore+ (for Friends aged 36+) £600.00
              </option>
              {/* <option value="Test_Subscribed">Test Subscription (£1.00)</option> */}
            </Select>
            <Button onClick={handleSubmit}>Select Tier</Button>
          </div>
        )}
      </div>
    </Box>
  )
}

export default SubscribeAppPage

const styles = {
  "&.subscribePage": {
    ".formContainer": {
      background: "white",
      padding: 4,
      marginBottom: 4,
    },
    button: {
      mt: 3,
    },

    "& fieldset > div": {
      marginBottom: 3,
    },
    "& input[type=submit]": {
      bg: "accent",
      color: "white",
      cursor: "pointer",
      transition: "background 0.3s ease-in-out",
      "&:hover": {
        background: darken("accent", 0.1),
      },
    },
    "& input[type=text]:focus-visible": {
      outline: "red",
    },
    a: {
      color: "accent",
    },
    "& .invalid-feedback": {
      color: "red",
    },
    "& .error": {
      color: "red",
    },
  },
}
